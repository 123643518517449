import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { useThemeContext } from "../../Layout/ThemeContext";

import "./MediaList.scss";
import { removeEmptyKeys } from "../../../helpers";

export function ContentList({ items, className, style }) {
	const { theme } = useThemeContext();

	return (
		<div className={"row"}>
			{items.map(({ title, image, slug }, idx) => (
				<div className={className} key={idx}>
					<Link to={slug}>
						<div className={"row"}>
							<div className={"col-12"}>
								<img
									src={image}
									style={{
										...theme.image,
										...style,
									}}
								/>
							</div>
							<div
								className={"content-list__title col-12"}
								style={removeEmptyKeys({
									color: theme.color.text,
								})}
							>
								{title}
							</div>
						</div>
					</Link>
				</div>
			))}
		</div>
	);
}

ContentList.propTypes = {
	items: PropTypes.array,
	className: PropTypes.string,
	style: PropTypes.object,
};

ContentList.defaultProps = {
	items: [],
	className: "",
	style: {},
};
