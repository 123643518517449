import React from "react";
import { Empty } from "antd";
import PropTypes from "prop-types";

import { noData } from "../../constants";

export default function EmptyData({
	description,
	style,
	image,
}) {
	return (
		<Empty
			{...{
				description,
				style,
				image,
			}}
		/>
	);
}

EmptyData.propTypes = {
	description: PropTypes.string,
	style: PropTypes.object,
	image: PropTypes.object,
};

EmptyData.defaultProps = {
	description: noData,
	style: { marginBottom: "30px" },
	image: Empty.PRESENTED_IMAGE_SIMPLE,
};
