import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import { isMobile } from "react-device-detect";
import get from "lodash/get";
import cx from "classnames";

import { useThemeContext } from "../../Layout/ThemeContext";
import ImageSplit from "../../ImageSplit";

import "./gallerylist.scss";
import { removeEmptyKeys } from "../../../helpers";

export function GalleryList({ items, className, orientation }) {
	const mediaSize = {
		"Landscape": ["100%", ""],
		"Portrait": ["100%", ""],
		"Square": ["100%", isMobile ? 300 : ""],
		"default": ["100%", isMobile ? 300 : 500],
	};

	const [width, height] = mediaSize[orientation || "default"];

	function handleOpen(idx) {
		const pswp = document.getElementById("pswp-element");

		if (pswp && get(items, "length", 0)) {
			const images = items.map(({ src, h = 0, w = 0, title }) => {
				return {
					src,
					h,
					w,
					title,
				};
			});

			const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, images, {
				mouseUsed: true,
				bgOpacity: 0.9,
				shareEl: false,
				history: false, // IMPORTANT SET TO FALSE !!!
				index: idx,
			});

			gallery.listen("gettingData", (index, item) => {
				if (item.w < 1 || item.h < 1) {
					// unknown size
					let img = new Image();
					img.onload = function () {
						// will get size after load
						item.w = this.width; // set image width
						item.h = this.height; // set image height
						gallery.invalidateCurrItems(); // reinit Items
						gallery.updateSize(true); // reinit Items
					};
					img.src = item.src; // let's download image
				}
			});

			let prevIndex;

			gallery.listen("afterChange", () => {
				const currentIndex = gallery.getCurrentIndex();
				if (prevIndex !== currentIndex) {
					prevIndex = currentIndex;

					const hash = get(items, `[${prevIndex}].id`, "");

					if (hash) {
						location.hash = hash;
					}
				}
			});

			gallery.listen("close", () => {
				location.hash = "";
			});

			gallery.init();
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			const imgHash = get(location, "hash", "").slice(1);

			const imageIndex = items.findIndex(item => item.id === imgHash);

			if (imageIndex >= 0) {
				handleOpen(imageIndex);
			}
		}
	}, []);

	const itemsLength = get(items, "length", 0);

	return (
		<div className={"row gallery-list"}>
			{items.map(({ src = "", alt, title }, idx) => {
				let mdColWidth = 4;

				if (itemsLength === 2 || itemsLength % 3 === 2 && [itemsLength - 2, itemsLength - 1].includes(idx)) {
					mdColWidth = 6;
				}

				return (
					<GalleryItem
						key={`gallery-list-item-${idx}`}
						src={src}
						alt={alt}
						title={title}
						height={height}
						width={width}
						mdColWidth={mdColWidth}
						className={className}
						onClick={() => handleOpen(idx)}
					/>
				);
			},
			)}
		</div>
	);
}
GalleryList.propTypes = {
	items: PropTypes.array,
	className: PropTypes.string,
	orientation: PropTypes.string,
};

GalleryList.defaultProps = {
	items: [],
	className: "",
	orientation: "",
};

function GalleryItem({ src, alt, title, width, height, mdColWidth, className, onClick }) {
	const { theme } = useThemeContext();

	const isSplit = src instanceof Array;

	return (
		<div
			itemProp={"associatedMedia"}
			itemScope
			itemType={"http://schema.org/ImageObject"}
			className={cx("col-12 gallery-list__item d-flex flex-column", `col-md-${mdColWidth}`, className)}
			onClick={!isSplit ? onClick : () => Function}
		>
			<div className={"gallery-list__image-block"}>
				<ImageSplit
					src={src}
					alt={alt}
					mediaSize={[width, height]}
				/>
			</div>

			<p className={"pt-1"} style={removeEmptyKeys({ color: theme.color.text })}>{title}</p>
		</div>
	);
}

GalleryItem.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mdColWidth: PropTypes.number,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

GalleryItem.defaultProps = {
	src: "",
	alt: "",
	title: "",
	width: "100%",
	height: "",
	mdColWidth: 4,
	className: "",
	onClick: () => Function,
};

GalleryItem.displayName = "GalleryItem";
