import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../../components/Blocks";
import Pages from "../../../components/Pages";
import { FilesList } from "../../../components/Lists";
import { makeUrl, removePreloader } from "../../../helpers";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { Share } from "../../../widgets";
import EmptyData from "../../../components/EmptyData";
import {
	MetaDateModified,
	MetaDescription,
	MetaDateDateCreated,
	MetaDatePublished,
	MetaTranscript,
	MetaImage,
} from "../../../components/Meta";

export const query = graphql`
	query media_audiosDataQuery($slug: String!) {
		hasura {
			media_audios(where: { slug: { _eq: $slug } }) {
				title_full
				page_title_full
				page_title_short
				slug
				main_image
				main_image_preview
				seo
				content_blocks
				created_at
				updated_at
			}
		}
	}
`;

const FETCH_QUERY = gql`
	query FetchData($location: String!) {
		media_audios(where: { slug: { _eq: $location } }) {
			title_full
			title_full
			page_title_full
			page_title_short
			slug
			main_image
			main_image_preview
			seo
			content_blocks
			created_at
			updated_at
		}
	}
`;

export default function MediaAudiosPage({ location, data }) {
	const hasuraData = data.hasura;
	const path = last(compact(location.pathname.split("/")));

	const [isMount, setIsMount] = useState(false);
	const [audios, setAudios] = useState(get(hasuraData, "media_audios[0]", {}));

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount && fetchData && !error) {
			setAudios(get(fetchData, "media_audios[0]", {}));
		}

		removePreloader(!loading || error);
	}, [loading]);

	const { slug, main_image_preview, main_image, page_title_short, page_title_full, created_at, updated_at } = audios;

	const url = makeUrl.mediaAudio({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	return audios ? (
		<Pages entity={audios} url={url} >
			<div itemScope itemType="https://schema.org/AudioObject">
				<MetaDateModified content={updated_at} />
				<MetaDescription content={page_title_full} />
				<MetaDateDateCreated content={created_at} />
				<MetaDatePublished content={created_at} />
				<MetaImage content={imageSrc} />
				<MetaTranscript content={"AudioObject"} />
				<ContentBlock key={"breadcrumbs"}>
					<div className="container">
						<Breadcrumbs currentLabel={get(audios, "title_full", "")} />
					</div>
				</ContentBlock>
				<ContentBlock key={"fileList"}>
					<div className={"container"}>
						<TextBlock title={get(audios, "title_full", "")}>
							<FilesList items={audios} audio />
						</TextBlock>
					</div>
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

MediaAudiosPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

MediaAudiosPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
