/* eslint-disable */

import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Icon } from "antd";

import { makeUrl, removeEmptyKeys } from "../../../helpers";
import { RadioContext } from "../../../widgets";
import NoScript from "../../NoScript";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./radio.scss";

export function KindLink({ title_full, description, source, main_image, slug, type, className }) {
	const audio = useRef(false);
	const { radio, play, playPause, changeStation } = useContext(RadioContext);
	const { theme } = useThemeContext();

	const [playingRadio, setPlayingRadio] = useState(false);
	const [isPlay, setIsPlay] = useState(false);

	function handlePlaying() {
		// playPause();
		// changeStation(slug);
	}

	// useEffect(() => {
	// 	setPlayingRadio(get(radio, "slug", "") === slug);
	// }, [radio]);

	useEffect(() => {
		if (audio.current) {
			!isPlay ? audio.current.pause() : audio.current.play();
		}
	}, [isPlay]);

	return (
		<div className={"radio radio__link"}>
			<audio id={slug} ref={audio}>
				<source src={source} type="audio/mpeg" />
				Тег audio не поддерживается вашим браузером.
			</audio>
			<div className={className}>
				{title_full && (
					<Link
						to={makeUrl.radio({ type, slug })}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h3,
							lineHeight: theme.lineHeight.h3,
							color: theme.color.text,
						})}
					>
						{title_full}
					</Link>
				)}
				{
					description && (
						<p
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.text,
							})}
						>
							{description}
						</p>
					)
				}
				{main_image && <img src={get(main_image, "src", "")} alt={title_full || slug} />}
				<button
					className={cx(
						"radio__link__btn radio__link__btn_play",
						{ radio__link__btn_pause: isPlay },
					)}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
					onClick={() => setIsPlay(prev => !prev)}
				></button>
				<NoScript>
					<p>Чтобы прослушать радио, включите JavaScript</p>
				</NoScript>
			</div>
		</div>
	);
}

KindLink.propTypes = {
	title_full: PropTypes.string,
	description: PropTypes.string,
	source: PropTypes.string,
	main_image: PropTypes.object,
	slug: PropTypes.string,
	type: PropTypes.string,
	className: PropTypes.string,
};

KindLink.defaultProps = {
	title_full: "",
	description: "",
	source: "",
	main_image: {},
	slug: "",
	type: "radio",
	className: "",
};

export function KindIframe({ title_full, description, source, slug, type, className }) {
	const { theme } = useThemeContext();

	return (
		<div className={"radio radio__iframe"}>
			{
				title_full && (
					<h3>
						<Link
							to={makeUrl.radio({ type,
								slug })}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h3,
								lineHeight: theme.lineHeight.h3,
								color: theme.color.text,
							})}
						>
							{title_full}
						</Link>
					</h3>
				)
			}
			{
				description && (
					<p
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{description}
					</p>
				)
			}
			<div dangerouslySetInnerHTML={{ __html: source }} className={className} />
		</div>
	);
}

KindIframe.propTypes = {
	title_full: PropTypes.string,
	description: PropTypes.string,
	source: PropTypes.string,
	slug: PropTypes.string,
	type: PropTypes.string,
	className: PropTypes.string,
};

KindIframe.defaultProps = {
	title_full: "",
	description: "",
	source: "",
	slug: "",
	type: "radio",
	className: "",
};

export function MainPageRadio({ data }) {
	const { theme } = useThemeContext();
	const { source, slug, title_full } = data;
	const [playing, setPlaying] = useState(false);

	const handlePlaying = () => {
		if (playing) {
			setPlaying(false);
			document.getElementById(slug).pause();
		} else {
			setPlaying(true);
			document.getElementById(slug).play();
		}
	};

	return (
		<div
			className={"radio__fixed"}
			style={removeEmptyKeys({
				backgroundColor: theme.color.body,
			})}
		>
			<audio id={slug}>
				<source src={source} type="audio/mpeg" />
				Тег audio не поддерживается вашим браузером.
			</audio>
			<div>
				{
					title_full && (
						<span>
							<Link
								to={makeUrl.radio(data)}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								{title_full}
							</Link>
						</span>
					)
				}
				<button onClick={handlePlaying} style={{ background: "none" }}>
					{playing ? (
						<Icon
							type="pause"
							style={removeEmptyKeys({
								marginLeft: "5px",
								verticalAlign: "sub",
								color: theme.color.text,
							})}
						/>
					) : (
						<Icon
							type="caret-right"
							style={removeEmptyKeys({
								marginLeft: "5px",
								verticalAlign: "sub",
								color: theme.color.text,
							})}
						/>
					)}
				</button>
			</div>
		</div>
	);
}

MainPageRadio.propTypes = {
	data: PropTypes.object,
};

MainPageRadio.defaultProps = {
	data: {},
};
