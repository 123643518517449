import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Icon } from "../../Media";
import NoScript from "../../NoScript";

import "./fileslist.scss";

export function FilesList({ className, items, file, audio }) {
	return (
		<div className={`row files-list ${className}`}>
			<ul className={"mt-4 files-list__list"}>
				{audio && (
					<>
						<NoScript>
							<p>Чтобы прослушивать аудио, включите JavaScript</p>
						</NoScript>
						<li className={"files-list__item  d-flex align-items-center"}>
							<Icon id={"audio"} style={{ height: "4rem",
								width: "4rem" }} />
							<figure>
								<figcaption>{audio.title_full}</figcaption>
								<audio controls>
									<source src={get(audio, "src", "")} type="audio/mpeg" />
									<source src={get(audio, "src", "")} type="audio/ogg" />
									<p>Ваш браузер не поддерживает HTML5 аудио.</p>
								</audio>
							</figure>
						</li>
					</>
				)}

				{file &&
					items.map(({ title, src }, idx) => (
						<li key={`files-list-item-${idx}`} className={"files-list__item  d-flex align-items-center"}>
							<Icon id={"audio"} style={{ height: "4rem",
								width: "4rem" }} />
							<a href={src}>
								<h4>{title}</h4>
							</a>
						</li>
					))}
			</ul>
		</div>
	);
}

FilesList.propTypes = {
	className: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title_full: PropTypes.string,
			src: PropTypes.string,
		}),
	),
	file: PropTypes.bool,
	audio: PropTypes.bool,
	book: PropTypes.bool,
};

FilesList.defaultProps = {
	className: "",
	items: [],
	file: false,
	audio: false,
	book: false,
};
